<template>
  <div class="wrapper" v-loading="loading">
    <template v-if="!loading">
      <close-captch v-if="unlogin" :showFooter="false"></close-captch>
      <div v-else>
        <router-link :to="{ name: 'Home' }">
          <div class="logo">
            <img src="/assets/img/light/homelogo.png" alt="" />
          </div>
        </router-link>
        <div class="logged">
          <p>{{ $t('other.views.other.loggedAccount') }}</p>
          <div class="account">
            <img :src="accountLogo" alt="">
            <span>{{$store.getters.userInfo.username}}</span>
          </div>
          <template v-if="$store.getters.userInfo.api_token">
            <p class="tip">{{ $t('other.views.other.openClientLogin') }}</p>
            <div class="btn" @click="toClient">{{ $t('other.views.other.loginSandbox') }}</div>
          </template>
          <template v-else>
            <p class="tip">{{ $t('other.views.other.createKey') }}</p>
            <div class="btn" @click="genKey">{{ $t('other.views.other.generateKey') }}</div>
          </template>
          <p class="switch">{{ $t('other.views.other.isYourAccount') }}<span @click="switchAccount">{{ $t('other.views.other.switchAccount') }}</span></p>
        </div>
      </div>
    </template>
  </div>
</template>

<script setup>
import closeCaptch from '@/components/common/closeCaptch.vue'
import { checkLogin } from 'services/auth'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { ref, computed } from 'vue'
import { isDark } from 'app/style'
import emitter from '@/app/emitter.js'
import { loginIntranet } from 'app/auth'

const store = useStore()
const route = useRoute()
const router = useRouter()
const unlogin = ref(false)
const loading = ref(true)
const init = () => {
  checkLogin().then((res) => {
    loading.value = false
    if (!res.is_login) {
      unlogin.value = true
    } else {
      unlogin.value = false
    }
  })
}
init()
const accountLogo = computed(() => {
  if (store.getters.userInfo.user_pic && store.getters.userInfo.user_pic.type && store.getters.userInfo.user_pic.base64) {
    return `data:image/${store.getters.userInfo.user_pic.type};base64,${store.getters.userInfo.user_pic.base64}`
  }
  return isDark() ? require('@/assets/img/avatar.png') : require('@/assets/img/avatar-light.png')
})

const toClient = () => {
  location.href = `${route.query.protocol || 'atacli'}://?token=${store.getters.userInfo.api_token}&clientId=${route.query.clientId}`
}
const genKey = () => {
  router.push({
    name: 'account',
    query: {
      referrer: 'app-auth'
    }
  })
}
const isQihoo = window.location.host === 'ata.360zqaq.net'
const onIntranetLogin = () => {
  loginIntranet()
}
const switchAccount = () => {
  emitter.emit('showLogin')
}
const reloadPage = async () => {
  await store.dispatch('user/login')
  init()
}
const onQihooLogin = () => {
  setTimeout(() => {
    window.QHPass && window.QHPass.signIn(function(param) {
      reloadPage()
    })
  }, 500)
}
emitter.on('showLogin', () => {
  if (isQihoo) {
    onIntranetLogin()
  } else {
    onQihooLogin()
  }
})

</script>
<style lang="scss" scoped>
.wrapper {
  .logo {
    position: absolute;
    top: 10px;
    left: 16px;
    img {
      height: 28px;
    }
  }
  padding: 10px 16px;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .logged {
    margin-top: -100px;
    p:nth-child(1) {
      font-size: 16px;
      color: #3D3D3D;
    }
    .account {
      background: #F7F7F7;
      border-radius: 5px;
      padding: 10px 16px;
      min-width: 260px;
      display: flex;
      align-items: center;
      span {
        font-size: 14px;
        color: #3D3D3D;
      }
      img {
        width: 44px;
        height: 44px;
        border-radius: 50%;
        margin-right: 16px;
      }
    }
    .tip {
      font-size: 12px;
      color: #3D3D3D;
    }
    .btn {
      background: linear-gradient(270deg, #498DE2 0%, #41A3DE 100%);
      box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.05);
      border-radius: 5px;
      font-size: 14px;
      font-weight: bold;
      color: #FFFFFF;
      text-align: center;
      height: 35px;
      line-height: 35px;
      cursor: pointer;
    }
    .switch {
      font-size: 12px;
      color: #3D3D3D;
      span {
        color: #5264FF;
        cursor: pointer;
      }
    }
  }
}
</style>
