<template>
  <div class="wrapper">
    <center>
      <img src="@/assets/img/license.png">
    </center>
  </div>
</template>

<script setup>

</script>
<style lang="scss" scoped>
.wrapper {
  img {
    width: 70%;
  }
}
</style>
